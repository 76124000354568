import React, { useEffect, useState, useRef } from 'react';
import Lenis from '@studio-freight/lenis'
import { useLocation } from "react-router-dom";

import { Box } from '@mui/material';

import ServicesSection from './Components/Services/Services.js';
import HomeLandingSection from './Components/HomeLanding.js';
import AboutSection from './Components/About.js';
import PortfolioSection from './Components/Portfolio/Portfolio.js';
import ContactSection from './Components/Contact/Contact.js';
import FooterSection from './Components/Footer.js';
import CloudMntSection from './Components/MvBackground/CloudMnt.js';
import BounceCurveElement from './Components/BounceCurve/bounceCurve.js';
import PricingPlanSection from './Components/Plans/pricingPlans.js';

const textColor2 =  '#F8AD13';

const pageVariants = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
};

const pageTransition = {
  duration: 0.6,
};

function Homepage() {
  const location = useLocation();
  const [showScrollIndicator, setShowScrollIndicator] = useState(true);
  const footerRef = useRef(null);

  useEffect(() => {
    if (location.hash) {
      const element = document.querySelector(location.hash);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" }); // Smooth scrolling
      }
    }
  }, [location]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setShowScrollIndicator(false);
        } else {
          setShowScrollIndicator(true);
        }
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0.1,
      }
    );

    if (footerRef.current) {
      observer.observe(footerRef.current);
    }

    return () => {
      if (footerRef.current) {
        observer.unobserve(footerRef.current);
      }
    };
  }, []);

  return (
    <div>
      <CloudMntSection />
      <HomeLandingSection />
      <AboutSection />
      <BounceCurveElement />
      <PortfolioSection />
      <ServicesSection />
      <PricingPlanSection />
      <ContactSection />
      
      {showScrollIndicator && (
        <div style={styles.scrollIndicator}>
          <span style={styles.scrollText}>Scroll</span>
          <div style={styles.line} />
        </div>
      )}
      
      <Box ref={footerRef}>
        <FooterSection />
      </Box>
    </div>
  );
}

export default Homepage;

// Inline styles for scroll indicator
const styles = {
  scrollIndicator: {
    position: 'fixed',
    bottom: '20px', // Adjust as needed for spacing from bottom
    left: '50%',
    transform: 'translateX(-50%)',
    display: 'flex',
    flexDirection: 'column', // Stack text above the line
    alignItems: 'center',
    zIndex: 1000, // Ensure it stays above other content
    pointerEvents: 'none', // Prevent interference with scrolling or clicking
  },
  scrollText: {
    fontSize: '16px', // Font size of "Scroll" text
    color: textColor2, // Text color
    fontFamily: 'Roboto', // Font style
    fontWeight: 'bold',
    letterSpacing: '0.1em',
    marginBottom: '8px', // Space between text and line
  },
  line: {
    width: '2px', // Thickness of the line
    height: '30px', // Length of the line
    backgroundColor: textColor2, // Color of the line
  },
};