import React, { useEffect, useState } from 'react';
import {
    Box,
    Typography,
    useTheme,
    IconButton,
    Grid,
    useMediaQuery
} from '@mui/material';

import { ArrowBack, ArrowLeft, ArrowRight, ArrowForward } from '@mui/icons-material';
import { projects } from './projectData';

export default function PortfolioCarouselElement({ selectedPath }) {
    const theme = useTheme();

    // Mapping object to associate paths with images
    const imagesMap = {
        "Skyy Myles LLC": projects[0].images,
        "Nazarene Baptist": projects[1].images,
        "Ape Squad": projects[2].images,
        "Cheryl Rease":projects[3].images,
        "Lumberjack Robotics": projects[4].images,        
        "Mammoth Websites": projects[5].images,        
    };

    // Determine which set of images to use based on selectedPath
    const images = imagesMap[selectedPath] || [];
    const [selectedImage, setSelectedImage] = useState(null);
    const [visibleRange, setVisibleRange] = useState([0, 4]); // Show first images by default
    const isMdUp = useMediaQuery(theme.breakpoints.up('md'));

    // Set the initial selected image
    useEffect(() => {
        if (images?.length > 0) {
            setSelectedImage(images[0]);
        }
    }, []);

    // Adjust the visible range based on screen size
    useEffect(() => {
        setVisibleRange([0, isMdUp ? 4 : 3]);
    }, [isMdUp]);

    // Handler for navigating up in the thumbnail list
    const handleScrollUp = () => {
        setVisibleRange(([start, end]) => [Math.max(0, start - 1), Math.max(isMdUp ? 4 : 3, end - 1)]);
    };

    // Handler for navigating down in the thumbnail list
    const handleScrollDown = () => {
        setVisibleRange(([start, end]) => [start + 1, end + 1]);
    };

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                height: '100%', // Full viewport height
                backgroundColor: "#0f0f0f",
                overflow: 'hidden',
            }}
        >
            <Grid container spacing={3} sx={{ alignItems: 'center', }}>

                {/* Main selected image in the center, vertically aligned */}
                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                    <Box
                        component="img"
                        src={selectedImage}
                        //alt={product.title}
                        sx={{
                            width: { xs: "70vw", md: '50vw' },
                            height: 'auto',
                            maxHeight: '50vh', // Limit the maximum height of the image
                            borderRadius: '12px',
                            boxShadow: 5,
                            objectFit: 'cover',
                        }}
                    />
                </Grid>

                {/* Thumbnail images on the left */}
                <Grid item xs={12}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: { xs: 0, md: 0 },
                            alignItems: 'center',
                            justifyContent: "center"
                        }}
                    >
                        <IconButton onClick={handleScrollUp} disabled={visibleRange[0] === 0}>
                            <ArrowBack sx={{ color: "white" }} />
                        </IconButton>
                        {images?.slice(visibleRange[0], visibleRange[1]).map((image, index) => (
                            <Box
                                key={index}
                                component="img"
                                src={image}
                                //alt={product.title}
                                sx={{
                                    width: '15vw',
                                    height: 'auto',
                                    //maxHeight: {xs: '10vh', md: 'none'},
                                    cursor: 'pointer',
                                    borderRadius: '15px',
                                    border: selectedImage === image ? `3px solid ${theme.palette.primary.main}` : '2px solid transparent',
                                    boxShadow: selectedImage === image ? 5 : 0,
                                    mx: 1,
                                    objectFit: 'cover',
                                }}
                                onClick={() => setSelectedImage(image)}
                            />
                        ))}
                        <IconButton onClick={handleScrollDown} disabled={visibleRange[1] >= images?.length}>
                            <ArrowForward sx={{ color: "white" }} />
                        </IconButton>
                    </Box>
                </Grid>


            </Grid>
        </Box>
    );
}
