import React from 'react';
import { motion } from 'framer-motion';

import {
    Box,
    Toolbar,
    IconButton,
    Typography,
    Menu,
    Container,
    Avatar,
    Button,
    Tooltip,
    MenuItem,
    MenuList,
    Popover,
    List,
    ListItem,
    ListItemText,
    ClickAwayListener,
    Grow,
    Paper,
    Popper,
    Grid,
    Drawer,
    Hidden
} from '@mui/material';


const MyContactSection = () => {
    return (
        <Box
            sx={{
                borderRadius: '12px', // Adjust border radius as needed
                padding: '20px', // Adjust padding as needed
                backgroundColor: '#1E1E1E', // Background color
                textAlign: 'center', // Center align text
                //maxWidth: '300px', // Adjust max width as needed
            }}
        >
            <Grid item xs={12} sx={{ p: 2, pb: 0 }}>
                <Typography

                    sx={{
                        textAlign: { xs: 'center', md: 'left' },
                        color: 'white',
                        fontSize: {
                            xs: '2.25rem',
                            sm: '2.5rem',
                            md: '2rem',
                            lg: '3rem'
                        },
                        fontFamily: 'Montserrat',
                        fontWeight: 'bold',
                        //mb: 3,
                    }}
                >
                    Let's Work Together
                </Typography>
            </Grid>
            <Grid item xs={12} sx={{ p: 2, pt: 0 }}>
                <Typography
                    sx={{
                        textAlign: { xs: 'center', md: 'left' },
                        color: 'white',
                        fontSize: {
                            xs: '1.1rem',
                            sm: '1.5rem',
                            md: '1.25rem',
                            lg: '1.5rem'
                        },
                        fontFamily: 'Montserrat',
                        fontWeight: 'bold',
                        display: 'flex',
                        flexWrap: 'wrap', // Allow wrapping
                        lineHeight: '1.5', // Adjust line height for better readability
                        justifyContent: { xs: 'center', md: 'unset' }, // Center the content horizontally for xs breakpoint
                        mt: { xs: 1, md: 0 }
                    }}
                >
                    <span sx={{ whiteSpace: 'nowrap' }}>contact</span> {/* First part of the email */}
                    <span sx={{ whiteSpace: { xs: 'unset', sm: 'pre-wrap' }, display: { xs: 'block', sm: 'inline' } }}>@</span> {/* @ symbol displayed only for sm and larger breakpoints */}
                    <span sx={{ whiteSpace: { xs: 'nowrap', sm: 'pre-wrap' } }}>mammothwebsites</span> {/* Second part of the email */}
                    <span sx={{ whiteSpace: { xs: 'nowrap', sm: 'pre-wrap' } }}>.com</span> {/* Second part of the email */}
                </Typography>
            </Grid>
        </Box>



    );
};

export default MyContactSection;
