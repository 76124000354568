import React from 'react';

import {
  Box,
} from '@mui/material';

const LogoSection = () => {
  return (
    <Box
      sx={{
        borderRadius: '12px',
        padding: '20px',
        backgroundColor: '#1E1E1E',
        textAlign: 'center',
      }}
    >
      <Box
        component="img"
        src="https://mammoth-photos.s3.us-east-1.amazonaws.com/Images/logos/Minimal-logo-noback-invert-trim.png"
        alt="Mammoth Logo"
        sx={{
          width: { xs: "200px", sm: "300px", md: '400px' },
          //maxWidth: { xs: 'auto', md: '75%', lg: '100%' },
          height: 'auto',
        }}
      />

    </Box>
  );
};

export default LogoSection;
