import { useEffect, useState } from "react";
import { Box } from '@mui/material';

import TickerSection from './Ticker';
import Projects from "./HoverProject/Projects";

const PortfolioSection = () => {
  return (
    <Box sx={{backgroundColor: '#0f0f0f'}} id="portfolio-section" >
      <TickerSection/>
      <Projects />
    </Box>
  );
}

export default PortfolioSection;
