const s3BucketBaseURL = "https://mammoth-photos.s3.us-east-1.amazonaws.com/Images/";

export const projects = [
  {
    title: "Skyy Myles LLC",
    sub: "Real Estate Company",
    src: "https://mammoth-photos.s3.us-east-1.amazonaws.com/Images/SkyyMyles/MainImage.png",
    chip: ["Website Development", "Database Design", "Admin Controls", "Brand Expansion"],
    path: "/project/skyymylesllc",
    url: "https://skyymyles.com",
    desc: "'The Skyy's the limit', to accurately present the branding of Skyy Myles LLC Real Estate Company. We were tasked with building not only a visually informative website, but a platform that can be supported without developer assistance. The Skyy Myles website includes dynamic features such as database support, admin consoles, smart forms, and other interactive elements.",
    carouselComponent: "SkyyMylesCarouselElement",
    images: [
      `${s3BucketBaseURL}SkyyMyles/MainImage.png`,
      `${s3BucketBaseURL}SkyyMyles/1.png`,
      `${s3BucketBaseURL}SkyyMyles/2.png`,
      `${s3BucketBaseURL}SkyyMyles/3.png`,
      `${s3BucketBaseURL}SkyyMyles/4.png`,
      `${s3BucketBaseURL}SkyyMyles/5.png`,
      `${s3BucketBaseURL}SkyyMyles/6.png`,
      `${s3BucketBaseURL}SkyyMyles/7.png`,
      `${s3BucketBaseURL}SkyyMyles/8.png`,     
    ]
  },
  {
    title: "Nazarene Baptist",
    sub: "Church",
    src: "https://mammoth-photos.s3.us-east-1.amazonaws.com/Images/Nazarene/Main.png",
    chip: ["Website Development"],
    path: "/project/nazrenebaptist",
    url: "https://thenazarenebaptistchurch.com/",
    desc: "In order to allow Nazarene Baptist Church's members to stay informed and promote outreach, We built visually informative website to assist new and returning members see the chruch's impact and values. The Nazarene Baptist Church website includes dynamic features such as smart forms and other interactive elements.",
    carouselComponent: "NazareneCarouselElement",
    images: [
      `${s3BucketBaseURL}Nazarene/Main.png`,
      `${s3BucketBaseURL}Nazarene/Screenshot 2024-12-26 192744.png`,
      `${s3BucketBaseURL}Nazarene/Screenshot 2024-12-26 192753.png`,
      `${s3BucketBaseURL}Nazarene/Screenshot 2024-12-26 192821.png`,
      `${s3BucketBaseURL}Nazarene/Screenshot 2024-12-26 192828.png`,
      `${s3BucketBaseURL}Nazarene/Screenshot 2024-12-26 192835.png`,
      `${s3BucketBaseURL}Nazarene/Screenshot 2024-12-26 192912.png`,
      `${s3BucketBaseURL}Nazarene/Screenshot 2024-12-26 192922.png`,
      `${s3BucketBaseURL}Nazarene/Screenshot 2024-12-26 193004.png`,
      `${s3BucketBaseURL}Nazarene/Screenshot 2024-12-26 193133.png`,
      `${s3BucketBaseURL}Nazarene/Screenshot 2024-12-26 193147.png`,     
    ]
  },
  {
    title: "Ape Squad",
    sub: "Boxing Team",
    src: "https://mammoth-photos.s3.us-east-1.amazonaws.com/Images/ApeSquad/Main.png",
    chip: ["Website Development", "E-commerce", "Animations"],
    path: "/project/apesquadboxing",
    //url: "https://skyymyles.com",
    desc: "Our organization fights for the underserved youth of Baltimore by providing resources to compete in amateur boxing tournaments, access to life-skills training, and one-on-one mentorship. Our focus is youth development, with programs to build confidence, discipline, and a championship mindset.",
    carouselComponent: "ApeSquadCarouselElement",
    images: [
      `${s3BucketBaseURL}ApeSquad/Main.png`,
      `${s3BucketBaseURL}ApeSquad/Screenshot 2024-12-28 040408.png`,
      `${s3BucketBaseURL}ApeSquad/Screenshot 2024-12-28 040424.png`,
      `${s3BucketBaseURL}ApeSquad/Screenshot 2024-12-28 040447.png`,
      `${s3BucketBaseURL}ApeSquad/Screenshot 2024-12-28 040525.png`,
      `${s3BucketBaseURL}ApeSquad/Screenshot 2024-12-28 040536.png`,
      `${s3BucketBaseURL}ApeSquad/Screenshot 2024-12-28 040608.png`,
      `${s3BucketBaseURL}ApeSquad/Screenshot 2024-12-28 040626.png`,
      `${s3BucketBaseURL}ApeSquad/Screenshot 2024-12-28 040645.png`,
      `${s3BucketBaseURL}ApeSquad/Screenshot 2024-12-28 040703.png`,
      `${s3BucketBaseURL}ApeSquad/Screenshot 2024-12-28 040712.png`,
      `${s3BucketBaseURL}ApeSquad/Screenshot 2024-12-28 040723.png`,
      `${s3BucketBaseURL}ApeSquad/Screenshot 2024-12-28 040733.png`, 
    ]
  },
  {
    title: "Cheryl Rease",
    sub: "Boutique Clothing Brand",
    src: "https://mammoth-photos.s3.us-east-1.amazonaws.com/Images/CherylRease/Main.png",
    chip: ["Website Development", "E-commerce", "Headless Shopify", "Data Analytics", "Inventory Controls",],
    path: "/project/cherylreaseboutique",
    url: "https://cherylreneefashion.com",
    desc: "Hand crafted and African inspired, Cheryl Renee Fashion seeks to provide high quality and unique pieces for every clothing style. Designed for those who value cultural expression and sustainability, our collection embodies authenticity, style, and craftsmanship. Step into a world where fashion is not just worn but felt, a statement of identity and artistry. Embrace the beauty of Africa, one stitch at a time.",
    carouselComponent: "CherylReaseCarouselElement",
    images: [
      `${s3BucketBaseURL}CherylRease/Main.png`,
      `${s3BucketBaseURL}CherylRease/Screenshot 2024-12-28 041432.png`,
      `${s3BucketBaseURL}CherylRease/Screenshot 2024-12-28 041441.png`,
      `${s3BucketBaseURL}CherylRease/Screenshot 2024-12-28 041454.png`,
      `${s3BucketBaseURL}CherylRease/Screenshot 2024-12-28 041512.png`,
      `${s3BucketBaseURL}CherylRease/Screenshot 2024-12-28 041519.png`,
      `${s3BucketBaseURL}CherylRease/Screenshot 2024-12-28 041531.png`,
      `${s3BucketBaseURL}CherylRease/Screenshot 2024-12-28 041540.png`,
      `${s3BucketBaseURL}CherylRease/Screenshot 2024-12-28 041604.png`,
      `${s3BucketBaseURL}CherylRease/Screenshot 2024-12-28 041625.png`,
      `${s3BucketBaseURL}CherylRease/Screenshot 2024-12-28 041633.png`,      
    ]
  },
  {
    title: "Lumberjack Robotics",
    sub: "Robotics Team",
    src: "https://mammoth-photos.s3.us-east-1.amazonaws.com/Images/Lumberjack/Main.png",
    chip: ["App Development", "Data Analytics", "Admin Controls", "Secure Login"],
    path: "/project/lumberjackrobotics",
    desc: "Timber! While chopping down the competition, We were tasked with building a scouting platform to report, log, and analyize team data. The Lumberjack Robotics application includes real-time dynamic features such as login & verification support, databases, data analytics & visualization, admin console, smart forms, and other interactive elements.",
    carouselComponent: "LumberjackCarouselElement",
    images: [
      `${s3BucketBaseURL}Lumberjack/Main.png`,
      `${s3BucketBaseURL}Lumberjack/Screenshot 2024-04-14 013010.png`,
      `${s3BucketBaseURL}Lumberjack/Screenshot 2024-04-14 013033.png`,
      `${s3BucketBaseURL}Lumberjack/Screenshot 2024-04-14 013046.png`,
      `${s3BucketBaseURL}Lumberjack/Screenshot 2024-04-14 013054.png`,
      `${s3BucketBaseURL}Lumberjack/Screenshot 2024-04-14 013137.png`,
      `${s3BucketBaseURL}Lumberjack/Screenshot 2024-04-14 013153.png`,     
    ]
  },
  {
    title: "Mammoth Websites",
    sub: "2023 Website Design",
    src: "https://mammoth-photos.s3.us-east-1.amazonaws.com/Images/OldMammoth/Main.png",
    chip: ["Website Development"],
    path: "/project/oldmammoth",
    url: "https://a-mammothwebsites.vev.site/mammothwebsite",
    desc: "In the 2023 version of the Mammoth Websites website, our aim was to illustrate our diverse web design capabilities. With a vision in mind, we crafted an responsive and engaging website to help users experience our creativity & innovation. We made sure to encorporate different animations and storytelling elements.",
    carouselComponent: "OldMammothCarouselElement",
    images: [
      `${s3BucketBaseURL}OldMammoth/Main.png`,
      `${s3BucketBaseURL}OldMammoth/Screenshot 2024-04-14 012006.png`,
      `${s3BucketBaseURL}OldMammoth/Screenshot 2024-04-14 012122.png`,
      `${s3BucketBaseURL}OldMammoth/Screenshot 2024-04-14 012201.png`,
      `${s3BucketBaseURL}OldMammoth/Screenshot 2024-04-14 012248.png`,
      `${s3BucketBaseURL}OldMammoth/Screenshot 2024-04-14 012258.png`,
      `${s3BucketBaseURL}OldMammoth/Screenshot 2024-04-14 012321.png`,
      `${s3BucketBaseURL}OldMammoth/Screenshot 2024-04-14 012330.png`,
      `${s3BucketBaseURL}OldMammoth/Screenshot 2024-04-14 012338.png`,
      `${s3BucketBaseURL}OldMammoth/Screenshot 2024-04-14 012424.png`,
      `${s3BucketBaseURL}OldMammoth/Screenshot 2024-04-14 012435.png`,
      `${s3BucketBaseURL}OldMammoth/Screenshot 2024-04-14 012444.png`,   
    ]
  },
]