import './App.css';
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { AnimatePresence } from "framer-motion";

import Homepage from './HomePage';
import ButtonAppBar from './Components/AppBar/AppBar.js';
import ProjectPage from './Components/Portfolio/HoverProject/ProjectPage.js';
import ScrollToTop from './ScrollToTop.jsx';
import PageTransitionOverlay from './PageTransition.js';

function App() {
  const location = useLocation();
  const [nextPage, setNextPage] = useState("");

  const getPageTitle = (path) => {
    const staticTitles = {
      "": null,
      "/": null,
      "/about": "About",
    };
    return staticTitles[path] || "";
  };

  useEffect(() => {
    const pageTitle =
      location.pathname.startsWith("/project/")
        ? `${location.pathname.split("/project/")[1]}`
        : getPageTitle(location.pathname);
    
    setNextPage(pageTitle);
  }, [location]);

  return (
    <>
      
        <ScrollToTop />
        <ButtonAppBar />
        <Routes location={location} key={location.pathname}>
          <Route path="" element={<Homepage />} />
          <Route path="/" element={<Homepage />} />
          <Route path="/project/:name" element={<ProjectPage />} />
        </Routes>

    </>
  );
}

export default App;