import React, { useState, useEffect, useRef } from "react";
import { projects } from "./projectData";
import { Box, Grid, Typography, Chip } from "@mui/material";
import { motion, AnimatePresence, useInView } from "framer-motion";
import { useNavigate } from "react-router-dom";

export default function Projects() {
  const [hoveredProject, setHoveredProject] = useState(null);
  const mousePosition = useRef({ x: 0, y: 0 }); // Tracks the actual mouse position
  const laggedPosition = useRef({ x: 0, y: 0 }); // Tracks the lagged position
  const imageRef = useRef(null);
  const navigate = useNavigate();

  const handleMouseMove = (event) => {
    // Update the actual mouse position on mouse move
    mousePosition.current = {
      x: event.clientX,
      y: event.clientY,
    };
  };

  const handleMouseLeave = () => {
    setHoveredProject(null);
    if (imageRef.current) {
      imageRef.current.style.visibility = "hidden"; // Hide the image when mouse leaves
    }
  };

  const handleMouseOver = (index) => {
    setHoveredProject(index);
    if (imageRef.current) {
      imageRef.current.style.backgroundImage = `url(${projects[index].src})`; // Update image source
      imageRef.current.style.visibility = "visible"; // Show the image when hovering over a project
    }
  };

  const handleNavigate = (project) => {
    navigate(project.path, { state: project });
  };

  useEffect(() => {
    const updateLaggedPosition = () => {
      const dx = mousePosition.current.x - laggedPosition.current.x;
      const dy = mousePosition.current.y - laggedPosition.current.y;

      laggedPosition.current.x += dx * 0.07;
      laggedPosition.current.y += dy * 0.07;

      if (imageRef.current) {
        // Dynamically calculate the image dimensions
        const rect = imageRef.current.getBoundingClientRect();
        const width = rect.width;
        const height = rect.height;

        // Center the image using its dimensions
        const x = laggedPosition.current.x - width / 2;
        const y = laggedPosition.current.y - height / 2;

        imageRef.current.style.transform = `translate(${x}px, ${y}px)`;
      }

      requestAnimationFrame(updateLaggedPosition);
    };

    updateLaggedPosition();

    return () => cancelAnimationFrame(updateLaggedPosition);
  }, []);

  return (
    <Box
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}
      sx={{
        width: "100vw",
        height: "100%",
        color: "white",
        //zIndex: 100,
        backgroundColor: "#0f0f0f", // Optional background for contrast
        position: "relative",
        pb: 10
      }}
    >
      <Grid
        container
        spacing={2}
        sx={{
          //width: '100%',
          px: "10vw",
          //borderBottom: '1px solid rgba(255, 255, 255, 0.2)',
        }}
      >
        {projects.map((project, i) => (
          <ProjectItem
            key={project.title}
            project={project}
            i={i}
            handleMouseOver={handleMouseOver}
            handleNavigate={handleNavigate}
            hoveredProject={hoveredProject}
          />
        ))}
      </Grid>

      {/* Image preview that lags behind the mouse */}
      <AnimatePresence>
        {hoveredProject !== null && (
          <motion.div
            ref={imageRef}
            initial={{ opacity: 0, scale: 0 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.1 }}
            transition={{ duration: 0.3 }}
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "25vw",
              height: "15vw",
              backgroundSize: "cover",
              backgroundPosition: "center",
              //objectFit: 'contain',
              border: "3px solid #F8AD13",
              borderRadius: "8px",
              pointerEvents: "none",
              visibility: "hidden", // Initially hidden
              transform: "translate(-9999px, -9999px)", // Offscreen position to prevent flicker
              //transition: "transform 0.1s ease-out", // Optional smoothing effect
            }}
          />
        )}
      </AnimatePresence>

    </Box>
  );
}

const ProjectItem = ({ project, i, handleMouseOver, handleNavigate, hoveredProject }) => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  return (

    <Grid
      item
      xs={12}
      key={project.title}
      onMouseOver={() => handleMouseOver(i)}
      onClick={() => handleNavigate(project)}
      sx={{
        padding: { xs: 0, sm: 10 },
        py: { xs: 5 },
        borderBottom: "1px solid rgba(255, 255, 255, 0.2)",
        "&:hover": {
          cursor: "pointer",
          //backgroundColor: "rgba(255, 255, 255, 0.1)", // Optional hover effect
        },
      }}
    >
      <motion.div
        ref={ref}
        initial={{ opacity: 0, y: 50 }}
        animate={isInView ? { opacity: 1, y: 0 } : {}}
        transition={{ duration: 1, delay: 0.5 }}
      >
        <Grid container>
          <Grid item xs={12} sm={.5}
            sx={{
              display: "flex",
              justifyContent: { xs: "center", sm: "left" },
              //alignItems: "center",
            }}
          >
            <Typography
              sx={{
                color: hoveredProject === i ? "#F8AD13" : "white",
                fontSize: {
                  xs: "1.5rem",
                  sm: "1.5rem",
                  md: "1.5rem",
                  lg: "1.5rem",
                },
                fontFamily: "Montserrat",
                //mb: { xs: 0, sm: 5 },
              }}
            >
              {i + 1}.
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6.5}
            sx={{
              display: "flex",
              justifyContent: { xs: "center", sm: "left" },
              alignItems: "center",
              mb: { xs: 2, sm: 0 },
            }}
          >
            <motion.div
              animate={{
                x: hoveredProject === i ? 10 : 0,
              }}
              transition={{
                duration: 0.3,
              }}
            >
              <Typography
                sx={{
                  color: hoveredProject === i ? "#F8AD13" : "white",
                  fontSize: {
                    xs: "1.5rem",
                    sm: "2rem",
                    md: "2.5rem",
                    lg: "3.25rem",
                  },
                  fontFamily: "Montserrat",
                  textAlign: { xs: "center", sm: "left" },
                }}
              >
                {project.title}
              </Typography>
              <Typography
                sx={{
                  color: hoveredProject === i ? "#F8AD13" : "white",
                  fontSize: {
                    xs: "1rem",
                    sm: "1.25rem",
                    md: "1.25rem",
                    lg: "1.25rem",
                  },
                  fontFamily: "Montserrat",
                  mt: { xs: 0, sm: -1 },
                  textAlign: { xs: "center", sm: "left" },
                }}
              >
                {project.sub}
              </Typography>
            </motion.div>
          </Grid>

          <Grid item xs={12} sm={5}
            sx={{
              display: "flex",
              justifyContent: { xs: "center", sm: "right" },
              //alignItems: "center",
            }}
          >
            {/* Chips */}
            <motion.div
              animate={{
                x: hoveredProject === i ? -10 : 0,
              }}
              transition={{
                duration: 0.3,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap", // Allow chips to wrap to the next line if necessary
                  gap: 1, // Spacing between chips
                  justifyContent: { xs: "center", sm: "flex-end" }, // Align chips to the right
                  alignItems: "center",
                  flexDirection: { xs: "column", sm: "row" },
                }}
              >
                {project.chip.map((chip, index) => (
                  <Chip
                    variant="outlined"
                    key={index}
                    label={chip}
                    sx={{
                      color: hoveredProject === i ? "white" : "white",
                      borderColor: hoveredProject === i ? "#F8AD13" : "white",
                    }}
                  />
                ))}
              </Box>
            </motion.div>
          </Grid>
        </Grid>
      </motion.div>
    </Grid>

  );
};
