import React, { useState } from 'react';
import {
    Container,
    TextField,
    Button,
    Typography,
    Grid,
    Divider,
    MenuItem,
    Popover,
    Box,
    Alert,
    Snackbar
  } from '@mui/material';

import axios from 'axios';
import config from '../../config.js';

const environment = process.env.NODE_ENV || 'development';  // Determine the environment (e.g., development or production)
// Set the base URL for Axios requests
const api = axios.create({
  baseURL:  config[environment].apiUrl.replace('/api', ''),
});

const ContactFormSection = () => {
    const [formErrors, setFormErrors] = useState({});
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        number: '',
        website: '',
        message: '',
    });
    const [alertOnSuccess, setAlertOnSuccess] = useState(false);
    const [alertOnError, setAlertOnError] = useState(false);
    const [alertOnCodeError, setAlertOnCodeError] = useState(false);
  
    const handleInputChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        //console.log("Change:", name, value);
        setFormData({ ...formData, [name]: value });
        //setFormErrors({ ...formErrors, [name]: '' });
    };

    const alertClose = () => {
        setAlertOnSuccess(false);
        setAlertOnError(false);
        setAlertOnCodeError(false);
    }

    const handleSubmit = (event) => {
        event.preventDefault();

         // Validate the form fields
        const errors = {};
        for (const key in formData) {
            if (formData[key] === '' && key !== 'message' && key !== 'website') {
                errors[key] = 'This field is required';
            }
        }

        // If there are validation errors, update the state and prevent submission
        if (Object.keys(errors).length > 0) {
            setFormErrors(errors);
            setAlertOnError(true);
            //console.log("Errors:", errors);
            return;
        }
        
        api.post('/api/sendContact', formData)
            .then(response => {
                //console.log('Response:', response.data);
                setAlertOnSuccess(true)
            })
            .catch(error => {
                console.error('Error:', error);
                setAlertOnCodeError(true);
            });
    };

  return (

    <Box
        sx={{
            borderRadius: '12px',
            padding: '20px',
            backgroundColor: '#1E1E1E',
            textAlign: 'center',
            ml: {xs: 0, md: 3},
        }}
    >
       
            <Grid container spacing={2} >
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label="Name"
                        variant="standard"
                        color="warning"
                        required
                        sx={{ 
                            '& .MuiInputLabel-root': { color: 'white' }, // Make label white
                            '& .MuiInput-underline:before': { borderBottomColor: 'white' }, // Make bottom line white
                            '& .MuiInput-underline:after': { borderBottomColor: 'warning' }, // Make bottom line white when focused
                            '& .MuiInputBase-input': { color: 'white' }, // Make input text white
                        }} 
                        name="name"
                        value={formData.name}
                        onChange={handleInputChange}
                        error={!!formErrors.name}
                        helperText={formErrors.name || ""}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label="Email"
                        variant="standard"
                        color="warning"
                        required
                        sx={{ 
                            '& .MuiInputLabel-root': { color: 'white' }, // Make label white
                            '& .MuiInput-underline:before': { borderBottomColor: 'white' }, // Make bottom line white
                            '& .MuiInput-underline:after': { borderBottomColor: 'warning' }, // Make bottom line white when focused
                            '& .MuiInputBase-input': { color: 'white' }, // Make input text white
                        }} 
                        name="email"
                        value={formData.email}
                        onChange={handleInputChange}
                        error={!!formErrors.email}
                        helperText={formErrors.email || ""}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label="Phone Numer"
                        variant="standard"
                        color="warning"
                        required
                        sx={{ 
                            '& .MuiInputLabel-root': { color: 'white' }, // Make label white
                            '& .MuiInput-underline:before': { borderBottomColor: 'white' }, // Make bottom line white
                            '& .MuiInput-underline:after': { borderBottomColor: 'warning' }, // Make bottom line white when focused
                            '& .MuiInputBase-input': { color: 'white' }, // Make input text white
                        }} 
                        name="number"
                        value={formData.number}
                        onChange={handleInputChange}
                        error={!!formErrors.number}
                        helperText={formErrors.number || ""}
                    />
                </Grid>
                
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label="Current Website (Optional)"
                        variant="standard"
                        color="warning"
                        sx={{ 
                            '& .MuiInputLabel-root': { color: 'white' }, // Make label white
                            '& .MuiInput-underline:before': { borderBottomColor: 'white' }, // Make bottom line white
                            '& .MuiInput-underline:after': { borderBottomColor: 'warning' }, // Make bottom line white when focused
                            '& .MuiInputBase-input': { color: 'white' }, // Make input text white
                        }} 
                        name="website"
                        value={formData.website}
                        onChange={handleInputChange}
                        error={!!formErrors.website}
                        helperText={formErrors.website || ""}
                    />
                </Grid>
                
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label="Message"
                        variant="standard"
                        color="warning" 
                        multiline
                        rows={4}
                        sx={{ 
                            '& .MuiInputLabel-root': { color: 'white' }, // Make label white
                            '& .MuiInput-underline:before': { borderBottomColor: 'white' }, // Make bottom line white
                            '& .MuiInput-underline:after': { borderBottomColor: 'warning' }, // Make bottom line white when focused
                            '& .MuiInputBase-input': { color: 'white' }, // Make input text white
                        }} 
                        name="message"
                        value={formData.message}
                        onChange={handleInputChange}
                        error={!!formErrors.message}
                        helperText={formErrors.message || ""}
                    />
                </Grid>

                <Grid item xs={12} sx={{mt: 1.5}}>
                    <Button
                        variant="outlined"
                        sx={{
                            borderRadius: '999px', // Make the button oval
                            background: 'linear-gradient(to right, #F8AD13, #f57c00)',
                            color: 'white', // Set the text color to white
                            border: '3px solid #151515', // Set border to transparent initially
                            //boxShadow: 'inset 0 0 0 3px #F8AD13, inset 0 0 0 6px #CC8400',
                            '&:hover': {
                                backgroundColor: 'black', // Change background color on hover
                                background: 'black',
                                border: '3px solid black', // Change border color on hover
                                color: 'white',
                            },

                        }}
                        fullWidth
                        onClick={handleSubmit}
                    >
                        <Typography
                            sx={{
                                fontWeight: 600,
                                //letterSpacing: '.1rem',
                                fontSize: { md: '1rem', lg: '1rem', xl: '1.1rem'}, //resize based on screensize
                                fontFamily: 'Montserrat',
                            }}
                        >
                            Contact
                        </Typography>
                    </Button>
                    <Snackbar
                    open={alertOnSuccess}
                    autoHideDuration={4000}
                    onClose={alertClose}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                >
                    <Alert
                        onClose={alertClose}
                        sx={{width: '100%'}}
                        severity='success'
                        variant='filled'
                    >
                        <Typography 
                            //align='center'
                            sx={{ 
                                color: 'white',
                                fontSize: {
                                    xs: '2rem',
                                    sm: '2rem',
                                    md: '1.2rem',
                                    lg: '1.2rem'
                                },
                                fontFamily: 'Montserrat',
                                //fontWeight: 'bold',
                            }}
                        >
                            Sent
                        </Typography>
                    </Alert>
                </Snackbar>

                <Snackbar
                    open={alertOnError}
                    //autoHideDuration={4000}
                    onClose={alertClose}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                >
                    <Alert
                        onClose={alertClose}
                        sx={{width: '100%'}}
                        severity='warning'
                        variant='filled'
                        //textAlign="center" // Center the text
                    >
                        <Typography 
                            //align='center'
                            sx={{ 
                                color: 'white',
                                fontSize: {
                                    xs: '2rem',
                                    sm: '2rem',
                                    md: '1.2rem',
                                    lg: '1.2rem'
                                },
                                fontFamily: 'Montserrat',
                                //fontWeight: 'bold',
                            }}
                        >
                            Missing Fields
                        </Typography>
                    </Alert>
                </Snackbar>

                <Snackbar
                    open={alertOnCodeError}
                    autoHideDuration={4000}
                    onClose={alertClose}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                >
                    <Alert
                        onClose={alertClose}
                        sx={{width: '100%'}}
                        severity='error'
                        variant='filled'
                    >
                        <Typography 
                            //align='center'
                            sx={{ 
                                color: 'white',
                                fontSize: {
                                    xs: '2rem',
                                    sm: '2rem',
                                    md: '1.2rem',
                                    lg: '1.2rem'
                                },
                                fontFamily: 'Montserrat',
                                //fontWeight: 'bold',
                            }}
                        >
                            Form Error. Please email contact@mammothwebsites.com
                        </Typography>
                    </Alert>
                </Snackbar>
                </Grid>
                
          </Grid>
  
    </Box>
  );
};

export default ContactFormSection;
