import { Suspense, useState, useRef, useMemo, useEffect } from "react";
import { Canvas, useThree, useFrame } from "@react-three/fiber";
import {
  OrbitControls,
  useGLTF,
  Points,
  PointMaterial,
  Stars,
  Trail,
} from "@react-three/drei";
import * as THREE from "three";
//import { EffectComposer, Bloom } from '@react-three/postprocessing'
import { motion, useScroll, useTransform } from "framer-motion";
import Lenis from "lenis";
import { Box } from "@mui/material";

import Mountain from "./Snowy_mountain";
import Clouds from "./Cloud_ring";
import MoonModel2 from "./The_moon.jsx";

export default function CloudMntSection() {
  const ref = useRef(null);
  const [dimension, setDimension] = useState({ width: 0, height: 0 });
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["start start", "end start"],
  });
  const { height } = dimension;
  //console.log("height:", height)
  const backgroundY = useTransform(scrollYProgress, [0, 1], ["0%", "100%"]);

  useEffect(() => {
    const lenis = new Lenis();

    const raf = (time) => {
      lenis.raf(time);
      requestAnimationFrame(raf);
    };

    const resize = () => {
      setDimension({ width: window.innerWidth, height: window.innerHeight });
    };

    window.addEventListener("resize", resize);
    requestAnimationFrame(raf);
    resize();

    return () => {
      window.removeEventListener("resize", resize);
    };
  }, []);

  return (
    <div
      ref={ref}
      style={{
        width: "100%",
        height: "100vh",
        background: "#00000e",
        position: "relative",
        zIndex: -1,
      }}
    >
      <motion.div style={{ y: backgroundY, height: "100vh" }}>
        <video
          id="background-video"
          autoPlay
          loop
          muted // Add this to prevent audio playback
          playsInline
          src="https://nazarene-videos.s3.us-east-1.amazonaws.com/MountainVid.mp4"
          style={{
            position: "absolute",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            width: "100%",
            height: "100%",
            objectFit: "cover", // Ensure the video covers the entire area
            zIndex: -5,
          }}
        />
        <Box
          sx={{
            position: 'absolute',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            backgroundColor: 'common.black',
            opacity: 0.5, //Background Opacity
            zIndex: -1,
          }}
        />

        
        {/*<Canvas camera={{ position: [5, 2, -200], fov: 20 }} >

            <ambientLight intensity={1} />
            <ShootingStar/>
            <Mountain/>
            <Clouds/>
            <MoonModel2/>
            <Stars radius={100} depth={50} count={10000} factor={4} saturation={0} fade speed={1} />
        </Canvas>*/}
      </motion.div>
    </div>
  );
}

function ShootingStar() {
  const ref = useRef();
  let xspeed = 0.9; // Adjust the speed of the shooting star
  const yspeed = 0.9;

  useEffect(() => {
    const moveShootingStar = () => {
      // Move the shooting star to the first position
      ref.current.position.x = 100;
      ref.current.position.y = 100;
      ref.current.position.z = -5;

      // Calculate a random x position between two points
      const minX = -40; // Minimum x value
      const maxX = 50; // Maximum x value
      const randomX = minX + Math.random() * (maxX - minX);
      const randomNumber = Math.random();

      // Move the shooting star to the second position after 1 second
      setTimeout(() => {
        if (randomNumber < 0.5) {
          xspeed = -xspeed; // Move to the left
        } else {
          xspeed = Math.abs(xspeed); // Move to the right
        }

        ref.current.position.x = randomX;
        ref.current.position.y = 50;
        ref.current.position.z = 50;
      }, 1000);

      // Restart the timer
      setTimeout(moveShootingStar, 7000);
    };

    // Start the animation loop
    moveShootingStar();
  }, []);

  useFrame(() => {
    // Move the shooting star
    ref.current.position.x -= -xspeed;
    ref.current.position.y -= yspeed;
  });

  return (
    <Trail
      width={10}
      length={5}
      color={new THREE.Color(2, 1, 10)}
      attenuation={(t) => t * t}
    >
      <mesh ref={ref} position={[10, 50, 50]}>
        <sphereGeometry args={[0.25]} />
        <meshBasicMaterial color={[10, 1, 10]} toneMapped={false} />
      </mesh>
    </Trail>
  );
}
