import './styles.css';
import * as React from 'react';
import { useRef, useEffect } from "react";
import { motion, sync, useCycle } from "framer-motion";


// MUI Components
import {
  Box,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  Container,
  Avatar,
  Button,
  Tooltip,
  MenuItem,
  MenuList,
  Popover,
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  Grid,
  Drawer,
  AppBar,
  Divider,
  SwipeableDrawer,
} from '@mui/material';
import { Link } from "react-router-dom";

//App Imports
import { useDimensions } from "./use-dimensions.ts";
import { MenuToggle } from "./MenuToggle.tsx";
import { Navigation } from "./Navigation.tsx";

const sidebar = {
  open: (height = 1000) => ({
    clipPath: `circle(${height * 2 + 200}px at 115px 45px)`,
    backgroundColor: "#fff",
    transition: {
      type: "spring",
      stiffness: 50,
      restDelta: 2
    }
  }),
  closed: {
    clipPath: "circle(25px at 200px 45px)",
    backgroundColor: "rgba(0, 0, 0, 0)",
    transition: {
      delay: 0.5,
      type: "spring",
      stiffness: 400,
      damping: 40
    }
  }
};

export default function ButtonAppBar() {
  //const [open, setOpen] = React.useState(false);
  const [isOpen, toggleOpen] = React.useState(false);
  const containerRef = useRef(null);
  const { height } = useDimensions(containerRef);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        toggleOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar sx={{ backgroundColor: "transparent", boxShadow: 'none', mt: '25px', px: 3, }} >
        <Toolbar disableGutters>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <motion.div
              whileHover={{
                scale: 1.2, // Scale up when hovered
              }}
              transition={{
                duration: 0.3, // Animation duration
              }}
            >
              <Tooltip title="Home" placement="bottom">
                <Avatar
                  component={Link} // Use React Router's Link
                  to={`/#home-section`} // Navigate to the section with an ID
                  src="https://mammoth-photos.s3.us-east-1.amazonaws.com/Images/logos/Logo-nowords-noback-invert-trim.png"
                  alt="Logo"
                  variant='square'
                  sx={{
                    width: { xs: '60px', sm: '80px', md: '90px', },
                    height: { xs: '40px', sm: '50px', md: '60px', lg: '60px', xl: '60px' },
                    marginRight: '10px',
                    top: '7px',
                    mb: 3,
                    ml: 1,
                  }}
                />
              </Tooltip>
            </motion.div>
          </Box>

          <Box sx={{ flexGrow: 1, }} />

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              borderRadius: '0 0 0 20px',
              py: 3,
            }}
          >
            <motion.div
              whileHover={{
                scale: 1.2, // Scale up when hovered
              }}
              transition={{
                duration: 0.3, // Animation duration
              }}
            >
              <MenuToggle toggle={() => toggleOpen(true)} isOpen={isOpen} />
            </motion.div>
          </Box>
        </Toolbar>
      </AppBar>
      <motion.nav
        initial={false}
        animate={isOpen ? "open" : "closed"}
        custom={height}
        ref={containerRef}
        style={{ zIndex: isOpen ? 1001 : -100 }}
      >
        <motion.div className="background" variants={sidebar} />
        <Navigation isOpen={isOpen} />

      </motion.nav>
    </Box>
  );
}
